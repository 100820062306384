<template lang="pug">
  v-container(fluid)
    v-row(justify='end')
      .center-block.mr-3.ml-3
        v-text-field.mr-2.search-width(
          v-model='userSearch',
          placeholder='Поиск',
          height='40',
          dense,
          outlined,
          hide-details,
          append-icon='mdi-chevron-down'
        )
        v-btn.mr-2(
          color='accent',
          height='40',
          elevation='0',
          @click='addUser()'
        ) Создать нового пользователя
        v-btn(
          color='accent',
          height='40',
          elevation='0',
          @click='openPendingUserPopup()'
        ) Пользователи в ожидании
    v-row
      v-col
        v-select.selector--width(
          v-model='role',
          :items='listOfRoles',
          item-value='name'
          item-text='name',
          dense,
          clearable,
          outlined,
          hide-details,
          height='40px',
          placeholder='По ролям',
          append-icon='mdi-chevron-down',
          :menu-props='{ bottom: true, offsetY: true }',
        )
    div.mt-3
      v-data-table(
        :headers='headers',
        :loading='isLoading',
        :footer-props="{'items-per-page-text':'Записей на страницу', pageText: '{0}-{1} из {2}'}"
        loading-text='Данные загружаются, ожидайте'
        :items='filteredUsers'
      )
        template(v-slot:no-data)
          .text-center.no-data_background.pa-10(v-if='!isLoading')
            v-icon.mt-14(size='48', color='#BDBDBD') mdi-search-web
            h2.mt-4 Нет данных
            h3.mt-2 Попробуйте выбрать другие значения или создайте пользователя
        template(v-slot:item.name='{ item }')
          div.mt-6.mb-6
            span {{item.name}}
        template(v-slot:item.registrationTime='{ item }')
          tooltip(:text='new Date(item.registrationTime).toLocaleString()')
            span {{ new Date(item.registrationTime).toLocaleDateString() }}
        template(v-slot:item.lastLogin='{ item }')
          tooltip(:text='new Date(item.lastLogin).toLocaleString()')
            span {{ new Date(item.lastLogin).toLocaleDateString() }}
        template(v-slot:item.actions='{ item }')
          v-row.pa-0
            v-btn.mr-1(
              @click='openEditUserForm(item); currentId = item.id;',
              width='40',
              height='40',
              icon,
              outlined
            )
              v-icon(small) mdi-lead-pencil
            v-btn(
              @click='removeUser(item.id)',
              width='40',
              height='40',
              elevation='0'
              fab,
              color='accent'
            )
              v-icon(small) mdi-delete
      v-row
        v-dialog(width="1100" v-model='pendingUserPopup')
          v-card(width='1100')
            v-card-title
              div Пользователи в ожидании
            v-card-text.pt-8.pl-5.pr-5
              v-row
                v-col(cols='12')
                  v-data-table(
                    :items='getPendingUsers'
                    :loading='isLoading',
                    loading-text='Данные загружаются, ожидайте',
                    :headers='usersPending'
                    no-data-text='Нет данных'
                    hide-default-footer
                  )
                  v-divider
            v-card-actions
              v-spacer
                v-btn(
                  width='90',
                  outlined,
                  text,
                  height='40',
                  color='#616161',
                  elevation='0',
                  @click='pendingUserPopup = false'
                ) Отмена

      v-row
        v-dialog(v-model='dialogEditPasswordVisible', width='1100')
          v-card
            v-toolbar(elevation='0')
              v-toolbar-title
                .dialog-title Изменение пароля
              v-spacer
              v-toolbar-items
                v-btn.pt-5-pb-5.pl-6(
                  elevation='0',
                  color='accent'
                  plain,
                  icon,
                  @click='dialogEditPasswordVisible = false'
                )
                  v-icon(small) mdi-close
            v-card-text
              v-row.mt-8
                v-subheader.ml-12.pb-2 Новый пароль
                v-text-field.mr-5(
                  v-model='newPassword',
                  :rules="rules.password",
                  validate-on-blur,
                  placeholder='Новый пароль',
                  height='40',
                  dense,
                  outlined
                )
              v-row(justify='end').mt-10
                v-card-actions
                  v-spacer
                    v-btn.mr-2(
                      outlined,
                      text,
                      height='40',
                      color='#616161',
                      elevation='0',
                      @click='dialogEditPasswordVisible = false'
                    ) Отмена
                    v-btn(
                      height='40',
                      elevation='0',
                      color='accent',
                      @click='editPassword'
                    ) Сохранить изменения
      v-row
        v-dialog(v-model='dialogFormVisible', width='1100')
          v-card
            v-toolbar(elevation='0')
              v-toolbar-title
                .dialog-title {{ editUser == true ? 'Измение данных пользователя' : 'Создание пользователя' }}
              v-spacer
              v-toolbar-items
                v-btn.pt-5-pb-5.pl-6(
                  elevation='0',
                  plain,
                  icon,
                  @click='dialogFormVisible = false'
                )
                  v-icon(small) mdi-close
            v-card-text.pt-8
              v-tabs(v-model='tabname', slider-color='accent', color='#303133')
                v-tab(v-for='tab in tabs', :key='tab.value', :value='tab.value') {{ tab.name }}
              v-tabs-items(v-model='tabname')
                v-divider.mr-6.ml-5
                v-tab-item
                  v-card-text.ml-4.mt-2
                    v-form(
                      ref='ruleForm',
                    )
                      v-row.ml-10(v-if='editUser === false', align='center')
                        v-subheader.ml-9.pb-6 Логин
                          sup.text-error *
                        v-text-field.mr-6(
                          height='40',
                          outlined,
                          dense,
                          type='text',
                          value='login',
                          validate-on-blur,
                          placeholder='Логин',
                          :rules='rules.login',
                          v-model='userInfo.login'
                        )
                      v-row.ml-10(align='center')
                        v-subheader.ml-12.pb-6 Имя
                          sup.text-error *
                        v-text-field.mr-6(
                          height='40',
                          outlined,
                          dense,
                          value='name'
                          type='text',
                          validate-on-blur,
                          placeholder='Имя',
                          :rules='rules.name',
                          v-model='userInfo.name'
                        )
                      v-row.ml-3(align='center')
                        v-subheader.ml-4.pb-6 Почта (email)
                          sup.text-error *
                        v-text-field.mr-6(
                          height='40',
                          outlined,
                          dense,
                          value='email',
                          type='email',
                          validate-on-blur,
                          placeholder='Почта',
                          :rules='rules.email'
                          v-model='userInfo.email'
                        )
                      v-row.ml-9(align='center')
                        v-subheader.ml-6.pb-6 Телефон
                        v-text-field.mr-6(
                          height='40',
                          outlined,
                          dense,
                          value='phone'
                          type='tel',
                          placeholder='Телефон',
                          v-model='userInfo.phone'
                        )
                      v-row.ml-11(align='center')
                        v-subheader.ml-10 Роль
                        v-select.mr-6(
                          v-model='userInfo.role',
                          :items='getRoles',
                          item-value='name'
                          item-text='name',
                          dense,
                          clearable,
                          outlined,
                          hide-details,
                          height='40px',
                          placeholder='По ролям',
                          append-icon='mdi-chevron-down',
                          :menu-props='{ bottom: true, offsetY: true }',
                          @change='getCabinets(userInfo.role)'
                        )
                      v-row.ml-10.mt-6(align='center', v-if='editUser')
                        v-subheader.ml-7 Пароль
                        v-btn(
                          height='40',
                          outlined,
                          text,
                          large,
                          color='#616161',
                          elevation='0',
                          @click='newPassword = ""; oldPassword = ""; dialogEditPasswordVisible = true'
                        ) Изменить
                v-tab-item
                  v-container(fluid).mt-4.ml-3.mr-3
                    v-card.pt-4.pr-4.pl-4.mt-4(v-for='(item,i) of form.accesses', :key='item.name + i' elevation='1')
                      v-toolbar(elevation='0', v-if='form.accesses.length > 1')
                        v-subheader(v-if='userInfo.role === ""') Для начала выберите роль
                        v-spacer
                        v-btn(
                          elevation='0',
                          small,
                          color='#ff2400',
                          outlined,
                          @click='form.accesses.splice(i, 1)',
                        ) Удалить этот доступ
                      v-divider.mt-2
                      v-card-text.mt-4.mb-4
                        v-row.pl-11
                          v-subheader.pb-2 Тип кабинета
                          v-select(
                            height='40',
                            v-model='item.group',
                            :items='roleCabinets',
                            item-text='name',
                            item-value='id',
                            placeholder='Выберите доступ',
                            clearable,
                            outlined,
                            dense,
                            hide-details,
                            append-icon='mdi-chevron-down',
                            :menu-props='{ bottom: true, offsetY: true }'
                          )
                        v-row.ml-10.mt-4(v-if='item.group === "agency"', align='center')
                          v-subheader.ml-6.mb-2 Кабинет
                          v-select(
                            height='40',
                            v-model='item.name',
                            :items='getAgencyDictionary',
                            item-text='name',
                            item-value='id',
                            placeholder='Доступы',
                            clearable,
                            outlined,
                            dense,
                            hide-details,
                            append-icon='mdi-chevron-down',
                            :menu-props='{ bottom: true, offsetY: true }'
                          )
                        v-row.ml-10.mt-4(v-if='item.group === "account"', align='center')
                          v-subheader.ml-6.mb-2 Кабинет
                          v-select(
                            height='40',
                            v-model='item.name',
                            :items='getAllAccounts',
                            item-text='name',
                            item-value='id',
                            placeholder='Доступы',
                            clearable,
                            outlined,
                            dense,
                            hide-details,
                            append-icon='mdi-chevron-down',
                            :menu-props='{ bottom: true, offsetY: true }'
                          )
                        v-row.ml-10.mt-4(v-if='item.group === "report"', align='center')
                          v-subheader.ml-6.mb-2 Кабинет
                          v-select(
                            height='40',
                            v-model='item.name',
                            :items='reports',
                            item-text='name',
                            item-value='id',
                            placeholder='Доступы',
                            clearable,
                            outlined,
                            dense,
                            hide-details,
                            append-icon='mdi-chevron-down',
                            :menu-props='{ bottom: true, offsetY: true }'
                          )
                  v-btn.float-right.mb-8.mr-2(
                    height='40',
                    outlined,
                    large,
                    text,
                    color='#616161',
                    elevation='0',
                    @click='addAccess'
                  ) Добавить еще один доступ
                v-card-actions
                  v-spacer
                  v-btn(
                    height='40',
                    width='95'
                    @click='dialogFormVisible = false',
                    large,
                    outlined,
                    text,
                    color='#616161',
                    elevation='0'
                  ) Закрыть
                  v-btn.ml-4(
                    width='95',
                    height='40',
                    elevation='0',
                    color='accent',
                    large,
                    v-if='!editUser',
                    :disabled='form.login === "" || form.name === "" || form.email === ""',
                    @click='createNewUser'
                  ) Создать
                  v-btn(
                    width='160'
                    height='40',
                    large,
                    elevation='0',
                    color='accent',,
                    v-if='tabname == 1 && editUser',
                    @click='updateUserAccesses'
                  ) Изменить доступы
                  v-btn(
                    width='225'
                    height='40',
                    large,
                    elevation='0',
                    color='accent',
                    v-if='tabname == 0 && editUser',
                    @click='updateUserInfo'
                  ) Изменить основные данные

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import tooltip from '@/components/tooltip'
import loading_mixin from '@/mixins/loading_mixin'
import {ACCOUNTS} from '../store/const/root'
import {DICTIONARY_AGENCY} from '../store/const/user'
import {
  GET_ROLES,
  GET_USERS,
  CREATE_NEW_USER,
  PENDING_USERS,
  CHANGE_PASSWORD,
  UPDATE_USER_ACCESS,
  UPDATE_USER,
  USER_INFO,
  REMOVE_USER,
} from '../store/const/user'

export default {
  components: {tooltip},
  mixins: [loading_mixin],
  data: function () {
    return {
      pendingUserPopup: false,
      dialogEditPasswordVisible: false,
      newPassword: '',
      editUser: false,
      tabname: 'first',
      roleCabinets: [],
      tabs: [
        {name: 'Основные данные', value: 'second'},
        {name: 'Доступы', value: 'first'},
      ],
      headers: [
        {text: 'Пользователь', value: 'name', width: 200},
        {text: 'Роль', value: 'role', sortable: false},
        {text: 'E-mail', value: 'email', sortable: false},
        {text: 'Логин', value: 'login', sortable: false},
        {text: 'Телефон', value: 'phone', sortable: false},
        {text: 'Дата регистрации', value: 'registrationTime', sortable: false},
        {text: 'Последний вход', value: 'lastLogin', sortable: false},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      usersPending: [
        {text: 'Логин', value: 'User.login'},
        {text: 'E-mail', value: 'User.email'},
        {text: 'Роль', value: 'User.role'},
        {text: 'Ссылка', value: 'link'},
      ],
      access: {
        admin: [{name: ''}],
        agency: [{name: ''}],
        account: [{name: ''}],
        dash: [{name: ''}],
        report: [{name: ''}],
      },
      oldPassword: '',
      accounts: [],
      reports: [],
      self: '',
      loadedSelf: false,
      admin: [],
      users: [],
      dialogFormVisible: false,
      dialogUsersVisible: false,
      rules: {
        login: [v => !!v || 'Пожалуйста, введите пароль'],
        password: [
          v => !!v || 'Пожалуйста, введите пароль',
          v => v.length >= 4 || 'Пароль должен содержать больше 4 символов'
        ],
        name: [v => !!v || 'Пожалуйста, введите имя'],
        email: [
          v => !!v || 'Пожалуйста, введите email',
          v => /.+@.+/.test(v) || 'Введите корректный email'
        ],
      },
      currentId: '',
      userInfo: {
        login: '',
        name: '',
        isActive: true,
        email: '',
        phone: '',
        role: '',
      },
      form: {
        accesses: [
          {
            group: '',
            name: '',
          },
        ],
      },
      roles: [],
      userRoles: [],
      CurrentPage: 1,
      CountByPage: 30,
      userSearch: '',
      listOfRoles: [{name: 'Без роли'}],
      role: null,
    }
  },
  computed: {
    ...mapGetters(['error']),
    ...mapGetters('User', ['getRoles', 'getUsers', 'getPendingUsers']),
    ...mapGetters('Root', ['getAllAccounts', 'getAgencyDictionary']),
    filteredUsers() {
      let users = this.getUsers
      users = users.slice(
        (this.CurrentPage - 1) * this.CountByPage,
        (this.CurrentPage - 1) * this.CountByPage + this.CountByPage
      )
      if (this.role === 'Без роли') {
        users = users.filter((item) => item.role === '')
      } else if (this.role !== null) {
        users = users.filter((item) => item.role.toLowerCase().indexOf(this.role.toLowerCase()) !== -1)
      }

      if (this.userSearch.length > 0) {
        users = users.filter((item) => item.name.toLowerCase().indexOf(this.userSearch.toLowerCase()) !== -1)
      }

      return users
    },
  },
  mounted() {
    this.setLoadingActions()
    this.getRolesRequest()
    this.getUsersRequest()
  },

  methods: {
    ...mapActions('User', [
      GET_ROLES,
      GET_USERS,
      CREATE_NEW_USER,
      PENDING_USERS,
      CHANGE_PASSWORD,
      UPDATE_USER_ACCESS,
      UPDATE_USER,
      REMOVE_USER
    ]),
    ...mapActions('Root', [ACCOUNTS]),
    ...mapActions('User', [DICTIONARY_AGENCY]),
    setLoadingActions() {
      this.actions = [
        GET_ROLES,
        GET_USERS,
        CREATE_NEW_USER,
        PENDING_USERS,
        CHANGE_PASSWORD,
        UPDATE_USER_ACCESS,
        UPDATE_USER,
        REMOVE_USER,
        DICTIONARY_AGENCY,
        ACCOUNTS
      ]
    },
    async openPendingUserPopup() {
      await this.PENDING_USERS()
      if (this.isError(PENDING_USERS)) {
        this.$notify({
          type: 'error',
          text: 'Не удалось получить список пользователей',
        })
        return
      }
      this.pendingUserPopup = true
    },
    getCabinets(role) {
      let userRoles = this.getRoles

      for (let i = 0; i < userRoles.length; i++) {
        if (userRoles[i].name === role) {
          this.roleCabinets = userRoles[i].cabinets
        }
      }
    },
    async editPassword() {
      let data = {
        newPassword: this.newPassword,
        id: this.currentId
      }
      const result = await this.CHANGE_PASSWORD(data)
      if (this.isError(CHANGE_PASSWORD)) {
        this.$notify({
          type: 'error',
          text: 'Ошибка',
        })
        return
      }
      this.self = result.data
      this.loadedSelf = true
      this.currentId = result.data.id
      this.dialogEditPasswordVisible = false
      this.$notify({
        type: 'success',
        text: 'Пароль успешно изменен',
      })
    },
    async updateUserAccesses() {
      let userAccesses = this.parseUserAccesses()
      let data = {
        id: this.userInfo.id,
        accesses: userAccesses,
      }
      await this.UPDATE_USER_ACCESS(data)
      if (this.isError(UPDATE_USER_ACCESS)) {
        this.$notify({
          type: 'error',
          text: 'Не удалось обновить доступы',
        })
        return
      }
      this.$notify({
        type: 'success',
        text: 'Доступы успешно обновлены',
      })
      await this.getUsersRequest()
    },
    async updateUserInfo() {
      await this.UPDATE_USER(this.userInfo)
      if (this.isError(USER_INFO)) {
        this.$notify({
          type: 'error',
          text: 'Не удалось обновить данные',
        })
        return
      }
      this.$notify({
        type: 'success',
        text: 'Основные данные успешно обновлены',
      })
      await this.getUsersRequest()
    },
    async openEditUserForm(user) {
      await this.getDictionary()
      this.getCabinets(user.role)
      this.form.accesses = []

      for (const key in user.accesses.list) {
        if (user.accesses.list.hasOwnProperty(key)) {
          const element = user.accesses.list[key]
          let settingKeys = []
          for (const k in element.setting) {
            if (element.setting.hasOwnProperty(k)) {
              const e = element.setting[k]
              e == true ? settingKeys.push(k) : null
            }
          }
          this.form.accesses.push({
            name: key,
            group: element.group,
            types: element.types,
          })
        }
      }
      this.userInfo = {
        login: user.login,
        name: user.name,
        id: user.id,
        isActive: true,
        email: user.email,
        phone: user.phone,
        role: user.role,
      }
      this.dialogFormVisible = true
      this.editUser = true
    },
    async removeUser(id) {
      if (!confirm('Вы уверены что хотите удалить этого пользователя?')) {
        return
      }
      await this.REMOVE_USER()
      if (this.isError(REMOVE_USER)) {
        this.$notify({
          type: 'error',
          text: 'Пользователь не был удален',
        })
        return
      }
      this.$notify({
        type: 'success',
        text: 'Пользователь успешно удален',
      })
      this.dialogUsersVisible = false
      await this.this.getUsersRequest()
    },
    parseUserAccesses() {
      let userAccesses = {}
      let list = {}
      let accesses = this.form.accesses
      if (accesses[0].group) {
        userAccesses.accesses = {}
        userAccesses.accesses.list = list
        for (const key in accesses) {
          const element = accesses[key]
          list[element.name] = {
            group: element.group,
            name: element.name,
            types: [element.group],
          }
        }
      }
      return userAccesses.accesses
    },
    async createNewUser() {
      try {
        let newUser = this.userInfo
        newUser.accesses = this.parseUserAccesses()
        await this.CREATE_NEW_USER(newUser)
        if (!this.isError(CREATE_NEW_USER)) {
          this.$notify({
            type: 'success',
            text: 'Для завершения регистрации, необходимо следовать указаниям в письме, которое отправлено на указанную Вами почту.',
          })
          this.dialogFormVisible = false
          return
        }
        if (this.error(CREATE_NEW_USER).code === -32602) {
          this.$notify({
            type: 'error',
            title: 'Ошибка при создании',
            text: 'Такие логин, почта или телефон уже используются в системе.',
          })
        } else {
          this.$notify({
            type: 'error',
            title: 'Ошибка при создании',
            text: this.error(CREATE_NEW_USER).message,
          })
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Пользователь не создан',
        })
        console.error('ОШИБКА В СОЗДАНИИ ЮЗЕРА: ', error)
      }
    },
    async getUsersRequest() {
      await this.GET_USERS()
      if (this.isError(GET_USERS)) {
        this.Loading = false
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Нет доступа',
        })
      }
    },
    async getDictionary() {
      this.dialogFormVisible = true
      await this.DICTIONARY_AGENCY()
      if (this.isError(DICTIONARY_AGENCY)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось получить данные агенства',
        })
      }
      await this.ACCOUNTS()
      if (this.isError(ACCOUNTS)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось получить данные аккаунта',
        })
      }
    },
    addUser() {
      this.form = {
        accesses: [
          {
            group: '',
            name: '',
          },
        ],
      }
      this.userInfo = {
        login: '',
        name: '',
        isActive: true,
        email: '',
        phone: '',
        role: '',
      }
      this.roleCabinets = []
      this.editUser = false
      this.getDictionary()
    },
    addAccess() {
      this.form.accesses.push({
        group: '',
        name: '',
      })
    },
    async getRolesRequest() {
      await this.GET_ROLES()
      if (this.isError(GET_ROLES)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка при при получении ролей',
        })
        await this.$router.push('login/?sessionIsDead=true')
        return
      }
      this.getRoles.map((role) => {
        this.listOfRoles.push({
          name: role.name,
        })
      })
    },
  },
}
</script>

<style lang="scss">
.v-data-table th {
  color: #909399 !important;
  font-size: 14px !important;
}

.search-width {
  width: 230px;
}

.selector--width {
  width: 200px
}

.v-tab {
  text-transform: none !important;
}
</style>
